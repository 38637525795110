import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    return (
        <section className="nk-footer-22-main">
            <div className="footer-hny py-5">
                <div className="container py-lg-4">
                    <div className="sub-columns row">
                        <div className="sub-one-left col-lg-3 col-md-6">
                            <h6>About </h6>
                            <p className="">
                                Noukaan Innovations is an IT firm centered on ICT Services and Solutions, specializing in creating responsive, beautiful websites and mobile applications, business branding and consulting firms and startups
                            </p>
                        </div>
                        <div className="sub-two-right col-lg-3 col-md-6 my-md-0 my-5">
                            <h6>Services</h6>
                            <div className="">
                                <ul>
                                    <li><Link to="/">Website Development</Link></li>
                                    <li><Link to="/">Mobile App Development</Link></li>
                                    <li><Link to="/">Website Domain &amp; Hosting</Link></li>
                                    <li><Link to="/">Branding &amp; Design</Link></li>
                                    <li><Link to="/">Digital Marketing</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="sub-two-right col-lg-3 col-md-6 my-md-0 my-5">
                            <h6>Innovative Hub</h6>
                            <div className="">
                                <ul>
                                    <li><Link to="/">Innovative Hub</Link></li>
                                    <li><Link to="/">BootCamps &amp; Trainings</Link></li>
                                    <li><Link to="/">Co-working Space</Link></li>
                                    <li><Link to="/">Virtual Offices</Link></li>
                                    <li><Link to="/">Hub Events &amp; Programs</Link></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="sub-two-right col-lg-3 col-md-6 my-md-0 my-5">
                            <h6>Quick links</h6>
                            <div className="footer-hny-ul">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/">About</Link></li>
                                    <li><Link to="/">Services</Link></li>
                                    <li><Link to="/">Contact</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/">Portfolio</Link></li>
                                    <li><Link to="/">Blog</Link></li>
                                    <li><Link to="/">Privacy Policy</Link></li>
                                    <li><Link to="/">Terms</Link></li>
                                </ul>
                            </div>
                        </div>
                    {/* </div>
                    <div className="sub-columns row"> */}
                        <div className="sub-one-left col-lg-3 col-md-6 mt-lg-0 mt-md-5">
                            <h6>Subscribe to our Newsletter</h6>
                            <form action="#" method="post" className="footer-newsletter">
                                <input type="email" name="email" className="form-input" placeholder="Enter your email.." />
                                <button type="submit" className="btn1"><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="below-section">
                <div className="container">
                    <div className="copyright-footer row">
                        <div className="columns col-lg-6">
                            <ul className="social footer">
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Noukaan-102891411296682/">
                                        <span className="fa fa-facebook" aria-hidden="true"></span>
                                    </a>
                                </li>
                                
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=2348147385542">
                                        <span className="fa fa-whatsapp" aria-hidden="true"></span>
                                    </a>
                                </li>

                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://twitter.com/noukaan_ws">
                                        <span className="fa fa-twitter" aria-hidden="true"></span>
                                    </a>
                                </li>

                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/noukaan/">
                                        <span className="fa fa-instagram" aria-hidden="true"></span>
                                    </a>
                                </li>
                                
                                <li>
                                    <a target="_blank" rel="noreferrer" href="mailto:contact@noukaan.com">
                                        <span className="fa fa-envelope" aria-hidden="true"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="columns copy-right col-lg-6">
                            <p>
                                © {new Date().getFullYear()}. All rights reserved. {" "} 
                                <Link to="/">
                                    Noukaan Innovations
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => scrollToTop()} id="movetop" title="Go to top">
                <span className="fa fa-long-arrow-up"></span>
            </button>
        </section>
    )
}

export default Footer
