import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            const header = document?.querySelector("#scroll-nav") || null;
            const scroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (header) {
                scroll > 50
                    ? header?.setAttribute(
                          "style",
                          `position: fixed;
                z-index: 99;
                width: 100%;
                border-bottom: 0.5px solid #0b1721;
                background: #fff;
                top: 0;`
                      )
                    : header?.removeAttribute("style");
            }
        });
    }, []);

    return (
        <section className="nk-top-header-content">
            <div className="hny-top-menu">
                <div className="top-hd">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="social-top col-lg-6">
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.facebook.com/Noukaan-102891411296682/"
                                    >
                                        <span className="fa fa-facebook"></span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.instagram.com/noukaan/"
                                    >
                                        <span className="fa fa-instagram"></span>
                                    </a>{" "}
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://twitter.com/noukaan_ws"
                                    >
                                        <span className="fa fa-twitter"></span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://api.whatsapp.com/send?phone=2348147385542"
                                    >
                                        <span className="fa fa-whatsapp"></span>
                                    </a>
                                </li>
                            </div>
                            <div className="accounts col-lg-6">
                                {/* <li className="top_li">
                                    <span className="fa fa-mobile"></span>
                                    <a target="_blank" rel="noreferrer" href="tel:+2348147385542">+234 814 738 5542</a>
                                </li> */}

                                <li className="top_li">
                                    <span className="fa fa-envelope-o"></span>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="mailto:contact@noukaan.com"
                                    >
                                        Contact us via email
                                    </a>
                                </li>
                                <li className="top_li1">
                                    <span className="fa fa-map-marker"></span>
                                    Abuja, Nigeria{" "}
                                </li>
                            </div>
                        </div>
                    </div>
                </div>

                <nav
                    className="navbar navbar-expand-lg navbar-light"
                    id="scroll-nav"
                >
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img
                                src="assets/images/newLogo.png"
                                alt="Logo"
                                title="Noukaan Innovations"
                                style={{ height: "40px" }}
                            />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">
                                        About
                                    </Link>
                                </li>
                                <li className=" nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="false"
                                        aria-expanded="false"
                                        style={{ cursor: "pointer" }}
                                    >
                                        Services
                                    </a>
                                    <ul
                                        className="dropdown-menu"
                                        style={{ minWidth: "250px" }}
                                    >
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">
                                                Web Development
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">
                                                Mobile App Development
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">
                                                Domain &amp; Hosting
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">
                                                Digital Marketing
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">
                                                Branding &amp; Design
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </section>
    );
};

export default Header;
