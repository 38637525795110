import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory, withRouter, Redirect } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AOS from 'aos';
import Home from "../pages/Home";
import Contact from "../pages/Contact";

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return null;
};

const ResetScroll = withRouter(ScrollToTop);

const Routes = () => {
    const history = useHistory();
    const init = async () => {
        AOS.init()
    }

    useEffect(() => {
        init();
      }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history])


    return (
        <Router>
            <ResetScroll />
            <Header />
            <Switch>
                <Route exact path="/home">
                <Redirect to={`/`} />
                </Route>
                <Route exact path="/" component={Home} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/contact-us" component={Contact} />
                <Route path="*">
                <Home />
                </Route>
            </Switch>
            <Footer />
        </Router>
    );
};


export default Routes;
