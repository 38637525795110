import React from 'react'

const Home = () => {
    const portfolios = [
        { image: 'rr.png', name: 'RetroResume', link: 'https://retroresume.com/', info: 'Effortlessly make a job-worthy resume' },
        { image: 'auckland.png', name: 'Auckland House Prices', link: 'https://aucklandhouseprices.co.nz/', info: 'Market Valuation Report' },
        { image: 'port3.jpg', name: 'Easy Connect', link: 'https://easyconnect.ng/', info: 'Business Listing Directory' },
        { image: 'port2.jpg', name: 'The Cable Cinema', link: 'https://thecablecinema.com/', info: 'Buy movie tickets online' },
        { image: 'port1.jpg', name: 'LOMBAZ Swift', link: 'https://lombaz.com/', info: 'Logistics and delivery gateway for sending or receiving goods' },
        { image: 'port4.jpg', name: 'Vykthr Portfolio', link: 'https://vykthr.com/', info: 'Personal Portfolio' },
        { image: 'port6.jpg', name: 'Myplug.ng', link: 'https://myplug.ng/', info: 'A platform to help you discover service providers closest to you' },
        { image: 'port5.jpg', name: 'MyNollyApp', link: 'https://mynollyapp.com/', info: 'Number one Nollywood streaming guide' },
    ]

    return (
        <>
            <section className="nk-banner-slider-main">
                <div className="bannerhny-content">
                    <div className="content-baner-inf">
                        <div id="slide" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="container">
                                        <div className="carousel-caption">
                                            <h3>Innovative <span className="b-ck">Agency </span></h3>
                                            <p>We provide new ideas &amp; creative solutions for businesses</p>
                                            <a href="about" className="banner-btn btn">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item item2">
                                    <div className="container">
                                        <div className="carousel-caption">
                                            <h3>Smart <span className="b-ck"> Selection </span></h3>
                                            <p>get the best team &amp; experts to ensure the application of better solutions</p>
                                            <a href="about" className="banner-btn btn">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item item3">
                                    <div className="container">
                                        <div className="carousel-caption">
                                            <h3>Grow <span className="b-ck">Business</span></h3>
                                            <p>Grow your business in design, brand &amp; innovation</p>
                                            <a href="about" className="banner-btn btn">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item item4">
                                    <div className="container">
                                        <div className="carousel-caption">
                                            <h3>Digital <span className="b-ck">Solutions</span></h3>
                                            <p>Let us get the world to see how amazing your brand is</p>
                                            <a href="about" className="banner-btn btn">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#slide" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#slide" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nk-content-6">
                <div className="content-6-mian py-5" data-aos="fade-up">
                    <div className="container py-lg-5">
                        <div className="content-info-in row">
                            <div className="content-gd col-lg-4">
                                <h3 className="hny-title">
                                    <span className="dot-1"> </span>Design <br/> 
                                    <span className="dot-1"> </span>Create <br/> 
                                    <span className="dot-1"> </span>Innovate <span className="dot-1">.</span>
                                </h3>
                            </div>
                            <div className="content-gd col-lg-4">
                                <p style={{ textAlign: "justify"}}>Noukaan Innovations is an IT firm centered on ICT Services and Solutions, specializing in creating responsive, beautiful websites and mobile applications and providing online marketing, business branding, web hosting and consulting firms and startups</p>
                            </div>
                            <div className="content-gd col-lg-4">
                                <p style={{ textAlign: "justify"}} >We also offer a wide range of digital marketing services focused on helping businesses increase their visibility online and offline. We harmonize marketing strategies with sales processes to drive traffic to your websites and applications, creating brand awareness and increasing sales for you and your businesses </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
        	<section className="nk-specification-6">
                <div className="specification-6-mian">
                    <div className="container-fluid">
                        <div className="align-counter-6-inf-cols row">
                            <div className="counter-6-inf-left col-lg-6">
                                <h3 className="hny-title two">Leading the way in Mobile Application Development<span className="dot-1">.</span></h3>
                            </div>
                            <div className="counter-6-inf-right col-lg-6">
                                <div className="specification" data-aos="fade-up">
                                    <div className="specification-icon">
                                        <span className="fa fa-pencil-square-o"></span>
                                    </div>
                                    <div className="specification-info">
                                        <h6><a href="#">Beautiful Design</a></h6>
                                        <p>Clean, Beautiful, Simple, and Functional Apps. We provide applications that work and display beautifully across all platforms.</p>
                                    </div>
                                </div>
                                <div className="specification" data-aos="fade-up">
                                    <div className="specification-icon">
                                        <span className="fa fa-pencil-square-o"></span>
                                    </div>
                                    <div className="specification-info">
                                        <h6><a href="#">Fast Development</a></h6>
                                        <p>Get your app to life in next to no time. We Use rich set of fully customizable native components to build native interfaces.</p>
                                    </div>
                                </div>
                                <div className="specification" data-aos="fade-up">
                                    <div className="specification-icon">
                                        <span className="fa fa-mobile"></span>
                                    </div>
                                    <div className="specification-info">
                                        <h6><a href="#">Fully Responsive</a></h6>
                                        <p>Acessible, gorgeous, platform-native User Interface. We build apps that adapt to all device screens smoothly and perfectly</p>
                                    </div>

                                </div>
                                <div className="specification" data-aos="fade-up">
                                    <div className="specification-icon">
                                        <span className="fa fa-apple"></span>
                                    </div>
                                    <div className="specification-info">
                                        <h6><a href="#">Cross-platform</a></h6>
                                        <p>Native mobile apps deployed across multiple platforms. Applications are built and deployed across iOS devices, Android devices, Desktop and more.</p>
                                    </div>

                                </div>
                                <div className="specification last-one" data-aos="fade-up">
                                    <div className="specification-icon">
                                        <span className="fa fa-lightbulb-o"></span>
                                    </div>
                                    <div className="specification-info">
                                        <h6><a href="#">Simplicity</a></h6>
                                        <p>Mobile applications with quick loading and response time, intuitive and very easy to use with low requirements</p>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <section className="nk-content-w-photo-6">
                <div className="content-photo-6-mian py-5">
                    <div className="container py-lg-5">
                        <div className="align-photo-6-inf-cols row">

                            <div className="photo-6-inf-right col-lg-7">
                                <h3 className="hny-title text-left">Our Services<span className="dot-1">.</span></h3>

                                <div className="row grids-innf">
                                    <div className="specification col-md-6" data-aos="fade-up">
                                        <div className="specification-icon">
                                            <span className="fa fa-globe"></span>
                                        </div>
                                        <div className="specification-info">
                                            <h6><a href="#">Website Development</a></h6>
                                            <p className="justify">We initiate business growth for our clients by developing rich featured innovative, highly functional, visually appealing websites. </p>
                                        </div>

                                    </div>
                                    <div className="specification col-md-6" data-aos="fade-up">
                                        <div className="specification-icon">
                                            <span className="fa fa-mobile"></span>
                                        </div>
                                        <div className="specification-info">
                                            <h6><a href="#">Mobile App Development</a></h6>
                                            <p className="justify">Get your business an Android and iOS mobile app. Apps for Social Networking, Sports, Bookings, News, Ecommerce, Utilities, Travel, Weather and more. </p>
                                        </div>

                                    </div>
                                    <div className="specification col-md-6" data-aos="fade-up">
                                        <div className="specification-icon">
                                            <span className="fa fa-pencil-square-o"></span>
                                        </div>
                                        <div className="specification-info">
                                            <h6><a href="#">Branding &amp; Design</a></h6>
                                            <p className="justify">We understand that your service is unique and so we spend time to create designs that are unique, and represent your brand in a ways not quite like anything else.</p>
                                        </div>

                                    </div>
                                    <div className="specification col-md-6" data-aos="fade-up">
                                        <div className="specification-icon">
                                            <span className="fa fa-line-chart"></span>
                                        </div>
                                        <div className="specification-info">
                                            <h6><a href="#">Digital Marketing</a></h6>
                                            <p className="justify">Our services are tailored to drive qualified users to your Website, Mobile Apps and Social Media Accounts ensuring that customers are driven to interact with your business</p>
                                        </div>

                                    </div>
                                    <div className="specification col-md-6" data-aos="fade-up">
                                        <div className="specification-icon">
                                            <span className="fa fa-database"></span>
                                        </div>
                                        <div className="specification-info">
                                            <h6><a href="#">Domain &amp; Web Hosting</a></h6>
                                            <p className="justify">We provide clients with superior hosting plans and environment that supports their businesses at all levels and also provides maximum security, scalability and data backup</p>
                                        </div>

                                    </div>
                                    <div className="specification last-one col-md-6" data-aos="fade-up">
                                        <div className="specification-icon">
                                            <span className="fa fa-comments-o"></span>
                                        </div>
                                        <div className="specification-info">
                                            <h6><a href="#">Business Consulting</a></h6>
                                            <p className="justify">Improving your business performance is important to us. We analyze businesses and help create solutions and services to ensure that businesses meet their core values and goals.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="photo-6-inf-left col-lg-5">
                                <img src="assets/images/img4.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
	        </section>

            
            <section className="nk-counter-6">
                <div className="conuter-66-info py-5">
                    <div className="container py-lg-5">
                        <div className="counter-grids-info row" data-aos="fade-up">
                            <div className="counter-gd col-lg-3">
                                <label><span className="fa fa-globe"></span> TRUST US</label>
                                <h4 className="hny-title">FUN <br/>FACTS</h4>
                            </div>
                            <div className="counter-gd col-lg-3">
                                <h6><span className="fa fa-phone"></span> 100%</h6>
                                <p>Customer Support</p>
                            </div>
                            <div className="counter-gd col-lg-3">
                                <h6><span className="fa fa-user-o"></span> 118+</h6>
                                <p>Global Customers</p>
                            </div>
                            <div className="counter-gd col-lg-3">
                                <h6><span className="fa fa-laptop"></span> 122+</h6>
                                <p>Completed Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
        	<section className="w3-gallery">
                <div className="porfolio-inf py-5">
                    <div className="container py-lg-5">
                        <h3 className="hny-title text-center"><span className="sub-title">Our</span> Portfolio</h3>
                        {/* <ul className="portfolio-categ filter my-md-5 my-4 p-0 text-center">
                            <li className="port-filter all active">
                                <a href="#">All</a>
                            </li>
                            <li className="cat-item-1">
                                <a href="#" title="Category 1">Websites</a>
                            </li>
                            <li className="cat-item-2">
                                <a href="#" title="Category 2">Mobile Apps</a>
                            </li>
                            <li className="cat-item-3">
                                <a href="#" title="Category 3">Branding &amp; Designs</a>
                            </li>
                        </ul> */}
                        <ul className="portfolio-area clearfix p-0 m-0 row">
                            {
                                portfolios.map((port, key) => (
                                    <li key={key} className="portfolio-item2 content" data-id="id-1" data-type="cat-item-1" data-aos="fade-up">
                                        <span className="image-block">
                                            <a target="_blank" className="image-zoom" href={port.link} data-gal="prettyPhoto[gallery]">
                                                <div className="content-overlay"></div>
                                                <img src={`assets/images/${port.image}`} className="img-fluid" alt="portfolio-img" />
        
                                                <div className="content-details fadeIn-bottom">
                                                    <h3 className="content-title">{port.name}</h3>
                                                    <p className="content-title">{port.info}</p>
        
                                                </div>
                                            </a>
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
        
            {/* <section>
                <div className="middle-tem-insidel">
                    <div className="progress-info">
                        <div className="right-side-img-tem-inside">
                        </div>
                        <div className="left-build-main-temps">
                            <h3 className="hny-title"><span className="sub-title">Work Abilities</span> Our Skills</h3>
                            <div className="progress-one" data-aos="fade-up">
                                <h4 className="progress-tittle">Web Design &amp; Development</h4>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "96%"}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="progress-one" data-aos="fade-up">
                                <h4 className="progress-tittle">Mobile Application Development</h4>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: "96%"}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="progress-one" data-aos="fade-up">
                                <h4 className="progress-tittle">Branding &amp; Graphic Design</h4>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{ width: "96%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="progress-one" data-aos="fade-up">
                                <h4 className="progress-tittle">Digital Marketing</h4>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{ width: "96%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="progress-one" data-aos="fade-up">
                                <h4 className="progress-tittle">Business Consulting</h4>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: "96%"}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </section> */}

            {/* <section className="nk-customers-6" style={{ marginBottom: "20px" }}>
                <div className="customers-6-infhny">
                    <div className="container py-lg-5">
                        <h3 className="hny-title text-center">Testimonials <span className="dot-1">.</span>
                        </h3>
                        <div className="customer-inner row mt-lg-5 mt-4">
                            <div className="customer-gd col-lg-4 col-md-6" data-aos="fade-up">
                                <div className="card text-left">
                                    <div className="card-body">
                                        <p style={{ textAlign: "center" }} className="card-text mb-4">
                                            Noukaan provides an excellent service, fast, creative, innovative and excellent. Highly recommended.
                                        </p>
                                        <div className="row tesimonial-content">
                                            <div className="col-3 client-img"> 
                                                <img className="card-img-top img-fluid" src="assets/images/c1.jpg" alt="" />
                                            </div>
                                            <div className="col-9 client-info">
                                                <a href="" className="customer-link">
                                                    <h3 className="card-title">
                                                        Kelechi Anoke</h3>
                                                </a>
                                                <span>CEO - KTG Wears</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-gd col-lg-4 col-md-6 mb-lg-0 mb-md-4" data-aos="fade-up">
                                <div className="card text-left">
                                    <div className="card-body">
                                        <p style={{ textAlign: "center" }} className="card-text mb-4">"They have the best team you could ever work with". All services are excellent. They develop functional and beautiful websites</p>
                                        <div className="row tesimonial-content">
                                            <div className="col-3 client-img"> <img className="card-img-top img-fluid" src="assets/images/c2.jpg" alt="" />

                                            </div>
                                            <div className="col-9 client-info">
                                                <a href="" className="customer-link">
                                                    <h3 className="card-title">Chris Horns</h3>
                                                </a>
                                                <span>CEO - Upick Mall</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="customer-gd col-lg-4 col-md-6" data-aos="fade-up">
                                <div className="card text-left">
                                    <div className="card-body">
                                        <p style={{ textAlign: "center" }} className="card-text mb-4">Excellent and Outstanding services at the best price! Noukaan is consistently creative and exceptional in designs</p>

                                        <div className="row tesimonial-content">
                                            <div className="col-3 client-img"> <img className="card-img-top img-fluid" src="assets/images/c3.jpg" alt="" />

                                            </div>
                                            <div className="col-9 client-info">
                                                <a href="" className="customer-link">
                                                    <h3 className="card-title">Ifeanyi Therry</h3>
                                                </a>
                                                <span>CEO - Therry Fashion</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         */}
        </>
    )
}

export default Home
