import React from 'react'

const Contact = () => {
    return (
        <>
            <section>

            </section>
        </>
    )
}

export default Contact